/* src/App.css */

:root {
  --primary-color: #000;
  --secondary-color: #fff;
  --accent-color: #0071e3;
  --text-color: #333;
  --border-radius: 8px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

html, body {
  height: 100%;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.main-content {
  padding-top: 80px;
  min-height: 100vh;
}

button {
  padding: 10px 20px;
  background-color: var(--accent-color);
  border: none;
  border-radius: var(--border-radius);
  color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #005bb5;
}
