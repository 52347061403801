:root {
  --primary-color: #000;
  --accent-color: #0071e3;
  --light-gray: #f2f2f2;
  --text-light: #555;
  --glass-bg: rgba(255, 255, 255, 0.2);
  --blur-effect: 15px;
  --border-radius: 12px;
}

body, html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: var(--primary-color);
  background-color: var(--light-gray);
  margin: 0;
  padding: 0;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  min-height: 100vh;
  animation: fadeInUp 0.8s ease-out;
}

.contact h1 {
  font-size: 3em;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 15px;
  animation: fadeInUp 1s ease-out;
}

.contact-intro {
  font-size: 1.2em;
  color: var(--text-light);
  max-width: 800px;
  text-align: center;
  margin-bottom: 50px;
  line-height: 1.6;
  animation: fadeInUp 1.1s ease-out;
}

/* Glassmorphic Contact Box */
.contact-box {
  width: 90%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  padding: 40px;
  background: var(--glass-bg);
  backdrop-filter: blur(var(--blur-effect));
  border-radius: var(--border-radius);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  opacity: 0;
  animation: fadeInUp 1.2s ease-out forwards;
}

@media (min-width: 768px) {
  .contact-box {
    grid-template-columns: 1fr 1fr;
  }
}

/* Keyframes for animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Contact Info */
.contact-info h2 {
  font-size: 2em;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 1.1em;
  color: var(--text-light);
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: var(--primary-color);
  transition: border 0.3s ease, transform 0.2s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 8px rgba(0, 113, 227, 0.3);
  transform: translateY(-2px);
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #888;
}

/* Button Styling */
.contact button {
  padding: 15px;
  font-size: 1em;
  color: #ffffff;
  background-color: var(--accent-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.contact button:hover {
  background-color: #005bb5;
  transform: translateY(-2px);
}

.contact button:active {
  transform: translateY(0);
}

/* Responsive Styling */
@media (max-width: 768px) {
  .contact h1 {
    font-size: 2.5em;
  }

  .contact-intro {
    font-size: 1.1em;
    padding: 0 15px;
  }

  .contact-box {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .contact-info h2 {
    font-size: 1.7em;
  }

  .contact-info p {
    font-size: 1em;
  }

  .contact button {
    font-size: 0.9em;
  }
}
