:root {
  --primary-color: #333333;
  --secondary-color: #ffffff;
  --accent-color: #005bb5;
  --text-color: #555555;
  --border-radius: 10px;
  --box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
}

body, html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background-color: var(--secondary-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Keyframes for animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInStaggered {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hero Section with Fade-In-Up animation */
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  background: 
    linear-gradient(rgba(0, 0, 50, 0.6), rgba(0, 0, 80, 0.7)),
    url('../assets/milkyway.jpg') no-repeat center center/cover;
  color: var(--secondary-color);
  position: relative;
}

.hero-content {
  max-width: 750px;
  padding: 25px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: var(--border-radius);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  animation: fadeInUp 1s ease-out forwards;
}

.hero h1 {
  font-size: 3.2em;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.1em;
  margin-bottom: 30px;
  color: var(--secondary-color);
}

.cta-button {
  padding: 12px 30px;
  background-color: var(--accent-color);
  color: var(--secondary-color);
  font-weight: 500;
  border-radius: var(--border-radius);
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #003e85;
  transform: translateY(-2px);
}

/* Services Section with Staggered Fade-In animation */
.services {
  text-align: center;
  padding: 80px 20px;
  background: var(--secondary-color);
}

.services h2 {
  font-size: 2.3em;
  margin-bottom: 40px;
  color: var(--accent-color);
  animation: fadeInUp 1s ease-out;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.service-item {
  width: 280px;
  padding: 20px;
  background: var(--secondary-color);
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  opacity: 0;
  animation: fadeInStaggered 0.6s ease-out forwards;
  animation-delay: calc(var(--delay-index) * 0.2s);
}

.service-item:hover {
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
}

.service-item h3 {
  font-size: 1.4em;
  margin-bottom: 10px;
  color: var(--accent-color);
}

.service-item p {
  font-size: 1em;
  color: var(--text-color);
}

/* Technologies Section */
.technologies {
  text-align: center;
  padding: 80px 20px;
  background: #f4f6f8;
}

.technologies h2 {
  font-size: 2.3em;
  margin-bottom: 20px;
  color: var(--accent-color);
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 40px;
  margin-top: 20px;
  justify-items: center;
}

.tech-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background: var(--secondary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 0.7s ease-out forwards;
}

.tech-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
}

.tech-item img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 10px;
}

.tech-item p {
  font-size: 1em;
  color: var(--text-color);
}
