/* src/styles/Projects.css */

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 5%;
  color: #e1e1e1;
  background-color: transparent;
}

.projects h1 {
  font-size: 2.5em;
  color: #00eaff;
  margin-bottom: 30px;
  text-shadow: 0 0 10px rgba(0, 234, 255, 0.5);
  text-align: center;
}

/* Project List */
.project-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.project-card {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Carousel Container */
.carousel-container {
  width: 100%;
  margin-bottom: 20px;
}

.project-slider {
  margin-bottom: 20px;
}

.slider-image img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

/* Project Information */
.project-info {
  text-align: center;
}

.project-info h3 {
  font-size: 1.8em;
  color: #00eaff;
  margin-bottom: 20px;
  text-shadow: 0 0 8px rgba(0, 234, 255, 0.5);
}

.project-info p {
  font-size: 1.1em;
  color: #dcdcdc;
  line-height: 1.6;
}

/* Hover and Shadow Effects */
.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 144, 255, 0.4);
}

/* Carousel Button Styling */
.carousel-button {
  background: none;
  border: none;
  color: #00f2ff;
  font-size: 2em;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s;
}

.carousel-button:hover {
  color: #ffffff;
}

.carousel-button:focus {
  outline: none;
}

/* Responsive Adjustments */

/* Tablet and Larger Screens */
@media (min-width: 768px) {
  .project-card {
    flex-direction: row;
    align-items: center;
  }

  .carousel-container {
    max-width: 600px;
    margin-right: 20px;
  }

  .project-info {
    flex: 1;
    text-align: left;
  }
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .projects h1 {
    font-size: 2em;
    margin-bottom: 25px;
  }

  .project-card {
    padding: 15px;
  }

  .carousel-container {
    max-width: 100%;
    margin-right: 0;
  }

  .project-info h3 {
    font-size: 1.5em;
  }

  .project-info p {
    font-size: 1em;
  }

  .carousel-button {
    font-size: 1.5em;
  }

  /* Reduce image height on mobile */
  .slider-image img {
    max-height: 10; /* Smaller height for mobile screens */
  }
}

/* Extra Small Screen Adjustments */
@media (max-width: 480px) {
  .projects h1 {
    font-size: 1.8em;
  }

  .project-info h3 {
    font-size: 1.3em;
  }

  .project-info p {
    font-size: 0.9em;
  }

  .carousel-button {
    font-size: 1.2em;
  }

  /* Further reduce image size on extra small screens */
  .slider-image img {
    max-height: 5px;
  }
}
